var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h3", [_vm._v("Services ")]),
          _c("h1", [
            _vm._v(_vm._s(_vm.isNewRoute ? "New" : "Edit") + " Service "),
          ]),
        ]),
        _c("div", { staticClass: "headerContainer__alignRight" }, [
          _c(
            "a",
            {
              staticClass: "btn btn--sm btn--black btn--text btn--withIconLeft",
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
            },
            [
              _c("icon", {
                attrs: { name: "arrow-left", width: "14", height: "14" },
              }),
              _vm._v("GO BACK"),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "container container--fullWidth" },
            [_c("VLoadSpinner")],
            1
          )
        : _c(
            "div",
            { staticClass: "container container--fullWidth" },
            [
              _c("ValidationObserver", [
                _c(
                  "div",
                  {
                    staticClass:
                      "container container--fullWidth grid grid__col-one-third",
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.closeDropdown,
                            expression: "closeDropdown",
                          },
                        ],
                        staticClass: "formGroup",
                      },
                      [
                        _c("h4", { staticClass: "form__title h-textCenter" }, [
                          _vm._v("Select Icon"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "wrapper--filled flexbox",
                            on: {
                              click: function ($event) {
                                _vm.isDropdownOpen = true
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "h-marginAuto" },
                              [
                                _c("icon", {
                                  staticClass: "serviceIcon",
                                  attrs: { name: _vm.service.icon },
                                }),
                                _c("icon", {
                                  staticClass: "dropdown",
                                  attrs: { name: "arrow-down" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isDropdownOpen,
                                expression: "isDropdownOpen",
                              },
                            ],
                            staticClass: "dropdown__menu",
                          },
                          _vm._l(_vm.serviceIcons, function (icon) {
                            return _c(
                              "li",
                              {
                                key: icon,
                                staticClass:
                                  "dropdown__item dropdown__item--sm",
                                class: { isActive: icon === _vm.service.icon },
                                on: {
                                  click: function ($event) {
                                    return _vm.setNewIcon(icon)
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  staticClass: "serviceIcon serviceIcon--sm",
                                  attrs: { name: icon },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "wrapper--filled wrapper--paddingLg" },
                      [
                        _c(
                          "div",
                          { staticClass: "formGroup formGroup--horizontal" },
                          [
                            _c(
                              "div",
                              { staticClass: "flex75" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Name",
                                    rules: { required: true },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "label",
                                            { staticClass: "formLabel" },
                                            [_vm._v("Name")]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.service.name,
                                                expression: "service.name",
                                              },
                                            ],
                                            staticClass:
                                              "formInput formInput--fullWidth",
                                            class: {
                                              hasError: errors.length > 0,
                                            },
                                            attrs: {
                                              name: "name",
                                              type: "text",
                                              placeholder: "Enter service name",
                                            },
                                            domProps: {
                                              value: _vm.service.name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.service,
                                                  "name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "formInput__hint formInput__hint--error",
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex25" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Price",
                                    rules: { required: true, numeric: true },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "label",
                                            { staticClass: "formLabel" },
                                            [_vm._v("Price")]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: _vm.service.price,
                                                expression: "service.price",
                                                modifiers: { number: true },
                                              },
                                            ],
                                            staticClass:
                                              "formInput formInput--fullWidth",
                                            class: {
                                              hasError: errors.length > 0,
                                            },
                                            attrs: {
                                              name: "price",
                                              type: "text",
                                              placeholder: "20",
                                            },
                                            domProps: {
                                              value: _vm.service.price,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.service,
                                                  "price",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "formInput__hint formInput__hint--error",
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "formGroup" },
                          [
                            _c("label", { staticClass: "formLabel" }, [
                              _vm._v("Description"),
                            ]),
                            _c("quill-editor", {
                              staticClass: "formInput",
                              staticStyle: { height: "300px", padding: "0" },
                              attrs: {
                                content: _vm.service.description,
                                options: _vm.editorOption,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onEditorChange($event)
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "formLabel",
                                staticStyle: { "white-space": "normal" },
                              },
                              [_vm._v("Note: Maximum 1000 characters.")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
    ]),
    _c("section", { staticClass: "section__bottom-button" }, [
      _c(
        "a",
        { staticClass: "btn btn--primary", on: { click: _vm.saveAllChanges } },
        [_vm._v("SAVE CHANGES")]
      ),
      _vm.existsOnDatabase
        ? _c(
            "a",
            {
              staticClass: "btn btn--danger btn--border",
              on: { click: _vm.openConfirmActionModal },
            },
            [_vm._v("DELETE SERVICE")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }